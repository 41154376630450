import { useField } from "formik";
import { TextField } from '@material-ui/core';
import React from 'react';

const BasePassword = (props: any) => {
    const [field, meta] = useField(props);

    return (
        <>
            <TextField
                {...props}
                name={field.name}
                error={meta.touched && meta.error !== undefined}
                helperText={meta.error}
                type="password"
                onBlur={field.onBlur}
                onChange={field.onChange}
            />
        </>
    );
};

export default BasePassword;
