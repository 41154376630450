import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom';
import { RootState } from '../../../store';
import { galleryListActions } from '../slices';
import Layout from '../../layout/components/layout.component';
import { Gallery } from '../schema';

class ListComponent extends Component<any, any> {
    static propTypes = {
        error: PropTypes.string,
        list: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.list();
    }

    componentDidUpdate(prevProps: Readonly<any>) {
        if (this.props.match.params.page !== prevProps.match.params.page)
            this.props.list(
                this.props.match.params.page &&
                decodeURIComponent(this.props.match.params.page)
            );
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const galleries: Gallery[] = Object.values(this.props.galleries ?? {});

        return (
            <Layout title="Galleries lists">
                {this.props.error && (
                    <div className="alert alert-danger">{this.props.error}</div>
                )}

                <p>
                    <Button component={RouterLink} to="/galleries/create" variant="contained">Create</Button>
                </p>

                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Media count</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {galleries.map((item, index) => (
                                <TableRow key={item.id} hover>
                                    <TableCell scope="row">
                                        <Link component={RouterLink} to={`/galleries/${item.id}`}>{item.name}</Link>
                                    </TableCell>
                                    <TableCell scope="row">
                                        {item.mediaCount}
                                    </TableCell>
                                    <TableCell>{item.description ?? '---'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/*{this.pagination()}*/}
            </Layout>
        );
    }

    // pagination() {
    //     const view = this.props.entities && this.props.entities['hydra:view'];
    //     if (!view || !view['hydra:first']) return;
    //
    //     const {
    //         'hydra:first': first,
    //         'hydra:previous': previous,
    //         'hydra:next': next,
    //         'hydra:last': last
    //     } = view;
    //
    //     return (
    //         <nav aria-label="Page navigation">
    //             <Link
    //                 to="."
    //                 className={`btn btn-primary${previous ? '' : ' disabled'}`}
    //             >
    //                 <span aria-hidden="true">&lArr;</span> First
    //             </Link>
    //             <Link
    //                 to={
    //                     !previous || previous === first ? '.' : encodeURIComponent(previous)
    //                 }
    //                 className={`btn btn-primary${previous ? '' : ' disabled'}`}
    //             >
    //                 <span aria-hidden="true">&larr;</span> Previous
    //             </Link>
    //             <Link
    //                 to={next ? encodeURIComponent(next) : '#'}
    //                 className={`btn btn-primary${next ? '' : ' disabled'}`}
    //             >
    //                 Next <span aria-hidden="true">&rarr;</span>
    //             </Link>
    //             <Link
    //                 to={last ? encodeURIComponent(last) : '#'}
    //                 className={`btn btn-primary${next ? '' : ' disabled'}`}
    //             >
    //                 Last <span aria-hidden="true">&rArr;</span>
    //             </Link>
    //         </nav>
    //     );
    // }
}

const mapState = (state: RootState) => {
    const {
        galleries,
        error,
    } = state.gallery.list;

    return { galleries, error };
};

const mapDispatch = {
    list: () => galleryListActions.retrieve(),
    reset: () => galleryListActions.reset()
};

export default connect(mapState, mapDispatch)(ListComponent);
