import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import { normalize } from 'normalizr';
import { mediaItemSchema } from '../schema';

const retrieve = createAsyncThunk(
    'media/fetch',
    async (galleryId: string) => {
        const response = await httpClient.get(`galleries/${galleryId}/medias`);

        return normalize(response.data, [mediaItemSchema]);
    }
);

const slice = createSlice({
    name: 'media/list',
    initialState: {
        error: null,
        loading: false,
    },
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }),
        success: ((state) => {
        }),
        reset: ((state) => {
            state.loading = false;
            state.error = null;
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                retrieve.fulfilled,
                (state, action) => {
                    state.loading = false;
                }
            )
    }
});

export const mediaListActions = { ...slice.actions, retrieve };
export default slice.reducer;
