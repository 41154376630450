import { schema } from 'normalizr';
import { Thumbnail } from './thumbnail';

export interface Media {
    id: string;
    name: string;
    original: string;
    thumbnails: Thumbnail;
    type: string;
}

export const mediaItemSchema = new schema.Entity('mediaItems');
