import React, { useEffect, useState } from 'react';
import Layout from './layout/components/layout.component';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Gallery, galleryApi } from './gallery';

export function Home() {
    const [galleries, setGalleries] = useState<Gallery[]>([]);

    useEffect(() => {
        galleryApi.getList({ limit: 5 })
            .then(result => setGalleries(Object.values(result.galleries ?? {})));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <div>
                <h2>Najnowsze galerie</h2>
                {galleries.map(item => (
                    <div key={item.id}>
                        <Link component={RouterLink} to={`/galleries/${item.id}`}>{item.name}</Link>
                    </div>
                ))}
            </div>
        </Layout>
    );
}
