import React, { useEffect, useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BaseCheckbox, BaseText } from '../../../core';
import { Save } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const disableSubmit = (loading: any, touched: any, valid: any) => loading
    || 0 === Object.keys(touched).length
    || !valid;

export type GalleryValues = {
    name: string,
    description: string,
    hidden: boolean
};

// todo props and state as typescript
export const Form = (props: any) => {
    const [initialValues, setInitialValues] = useState({
        name: '',
        description: '',
        hidden: false
    });
    const gallery = props.gallery;

    useEffect(() => {
        if (gallery) {
            setInitialValues({
                name: gallery.name,
                description: gallery.description ?? '',
                hidden: gallery.hidden
            })
        }
    }, [gallery]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
                name: Yup.string()
                    .max(255, 'Must be 255 characters or less')
                    .required('Required'),
                description: Yup.string()
                    .max(255, 'Must be 255 characters or less'),
                hidden: Yup.bool()
            })}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                props.save(values)
                    .catch((e: any) => setErrors(e.errors));

                setSubmitting(false);
            }}
        >
            {(formProps) => (
                <FormikForm noValidate>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <BaseText
                                name="name"
                                label="Name"
                                value={formProps.values.name}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <BaseText
                                name="description"
                                label="Description"
                                value={formProps.values.description}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <BaseCheckbox
                                        name="hidden"
                                        checked={formProps.values.hidden}
                                    />
                                } label="Hide gallery?"
                            />
                        </Grid>
                        <Grid item container spacing={1}>
                            <Grid item>
                                <Button type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Save/>}
                                        disabled={
                                            disableSubmit(props.loading, formProps.touched, formProps.isValid)
                                        }>Submit</Button>
                            </Grid>
                            <Grid item>
                                <Button component={RouterLink} to={props.cancelUrl} variant="contained">Back to
                                    list</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    )
};
