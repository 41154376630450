import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { history } from './helpers/history.helper';
import { reducer as form } from 'redux-form';
import { default as authentication } from './reducers/authentication/authentication';
import { galleryCreateReducer, galleryEditReducer, galleryListReducer, galleryShowReducer } from './features/gallery';
import { mediaCreateReducer, mediaDeleteReducer, mediaDownloadReducer, mediaListReducer } from './features/media';
import { homeReducer } from './features/home';
import { snackbarReducer } from './features/snackbar';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { backdropLoaderReducer } from './features/backdrop-loader';
import unauthorized from './http/interceptors/unauthorized.interceptor';
import jwt from './http/interceptors/jwt.interceptor';
import formErrors from './http/interceptors/form-errors.interceptor';
import backdropLoader from './http/interceptors/backdrop-loader.interceptor';

const store = configureStore({
    reducer: combineReducers({
        authentication,
        backdropLoader: backdropLoaderReducer,
        form,
        gallery: combineReducers({
            create: galleryCreateReducer,
            edit: galleryEditReducer,
            list: galleryListReducer,
            show: galleryShowReducer,
        }),
        home: combineReducers({
            show: homeReducer
        }),
        media: combineReducers({
            create: mediaCreateReducer,
            list: mediaListReducer,
            delete: mediaDeleteReducer,
            download: mediaDownloadReducer
        }),
        router: connectRouter(history),
        snackbar: snackbarReducer
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// init interceptors
backdropLoader(store);
unauthorized(store);
jwt();
formErrors();
// end

export default store;
