import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../helpers/history.helper';
import { default as GalleryRoutes } from './gallery/routes';
import { LoginPage } from '../features/security';
import { PrivateRoute } from './routing';
import { Home } from './home.component';
import AppSnackbar from './snackbar/components/snackbar.component';
import AppBackdropLoader from './backdrop-loader/components/backdrop-loader.component';

export default function App() {
    return (
        <>
            <ConnectedRouter history={history}>
                <Switch>
                    {GalleryRoutes}
                    <PrivateRoute exact path="/" component={Home}/>
                    <Route component={LoginPage} path="/login"/>
                    <Route render={() => <h1>Not Found</h1>}/>
                </Switch>
            </ConnectedRouter>
            <AppSnackbar/>
            <AppBackdropLoader/>
        </>
    )
}
