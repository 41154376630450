import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NormalizedData } from '../../../core';
import { Gallery } from '../../gallery';

type SliceState = {
    error: unknown | null,
    loading: boolean,
    newest: NormalizedData<Gallery> | undefined
}

const initialState: SliceState = {
    error: null,
    loading: false,
    newest: undefined
};

const slice = createSlice({
    name: 'home/show',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        success: ((state, action: PayloadAction<any>) => {
            state.newest = action.payload;
        }),
        reset: ((state) => {
            const { newest, loading, error } = initialState;

            state.newest = newest;
            state.loading = loading;
            state.error = error;
        }),
    },
    extraReducers: (builder) => {}
});

export const homeActions = { ...slice.actions };
export default slice.reducer;
