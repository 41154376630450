import axios from 'axios';
import { userActions } from '../../actions/authentication/user.actions';
import { Store } from 'redux';

const unauthorized = (store: Store) => {
    axios.interceptors.response.use(undefined, err => {
        if (401 === err.response.status) {
            store.dispatch(userActions.logout());
        }

        return Promise.reject(err);
    });
}

export default unauthorized;
