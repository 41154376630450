import { FieldHookConfig, useField } from "formik";
import { Checkbox } from '@material-ui/core';
import React from 'react';

const BaseCheckbox = (props: FieldHookConfig<string>) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field] = useField(props);

    return (
        <>
            <Checkbox
                {...field}
                disabled={props.disabled ?? false}
                checked={!!field.value ?? false}
            />
        </>
    );
};

export default BaseCheckbox;
