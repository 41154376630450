import { schema } from 'normalizr';

export interface Gallery {
    id: string;
    description: string | null;
    name: string;
    mediaCount: number;
}

export const gallerySchema = new schema.Entity('galleries');
