import axios from 'axios';
import { ErrorOther, SubmissionError } from 'redux-form';

type BackendFormError = {
    code: string,
    message: string,
    propertyPath: string
}

const formErrors = () => {
    axios.interceptors.response.use(undefined, err => {
        if (422 === err.response.status) {
            const violations: BackendFormError[] = err.response.data.violations ?? [];

            if (!violations) {
                throw Error(err);
            }

            let errors: { [key: string]: string } & ErrorOther = { _error: err };

            violations.forEach(violation =>
                errors[violation.propertyPath]
                    ? (errors[violation.propertyPath] +=
                    '\n' + errors[violation.propertyPath])
                    : (errors[violation.propertyPath] = violation.message)
            );

            throw new SubmissionError(errors);
        }

        return Promise.reject(err);
    });
}

export default formErrors;
