import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccountBox, ExitToApp, Menu as MenuIcon, Person as PersonIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import {
    AppBar,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    loginButton: {
        marginLeft: 'auto'
    },
    main: {
        padding: 20
    },
    header: {
        padding: 20
    }
}));

const Layout = (props: React.PropsWithChildren<any>) => {
    const classes = useStyles();

    const [state, setState] = React.useState({
        left: false,
    });

    const [anchorEl, setAnchorEl] = React.useState(null);

    const toggleDrawer = (anchor: any, open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
                        <MenuIcon/>
                    </IconButton>
                    <Drawer anchor="left" open={state['left']} onClose={toggleDrawer('left', false)}>
                        <List>
                            <ListItem button key="Homepage" component={Link} to="/">
                                <ListItemText primary="Homepage"/>
                            </ListItem>
                            <ListItem button key="Galleries list" component={Link} to="/galleries/">
                                <ListItemText primary="Galleries list"/>
                            </ListItem>
                        </List>
                    </Drawer>
                    <IconButton className={classes.loginButton} edge="end" color="inherit" aria-label="menu"
                                onClick={handleMenuOpen}>
                        <PersonIcon/>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem component={Link} to="/profile" disabled>
                            <ListItemIcon><AccountBox/></ListItemIcon>
                            Profile
                        </MenuItem>
                        <MenuItem component={Link} to="/login">
                            <ListItemIcon><ExitToApp/></ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <header className={classes.header}>
                <Typography variant="h5" component="h1" gutterBottom>
                    {props.title}
                </Typography>
                <div role="toolbar" aria-orientation="horizontal" aria-label="Toolbar">
                    {props.toolbar}
                </div>
            </header>
            <main className={classes.main}>
                <Grid container spacing={1}>
                    <Grid item xs>
                        {props.children}
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

export default Layout;
