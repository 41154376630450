import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoginForm } from './form.component';
import { userService } from '../../../services/user.service';
import { userActions } from '../../../actions/authentication/user.actions';
import { RootState } from '../../../store';

class Login extends Component<any, any> {
    constructor(props: any) {
        super(props);

        const user = userService.getUser();

        if (user) {
            this.props.dispatch(userActions.logout());
        }

        this.state = {
            username: '',
            password: '',
            submitted: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values: any) {
        this.setState({
            submitted: true,
            username: values.username,
            password: values.password
        });
        const { username, password } = this.state;
        const { dispatch } = this.props;

        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }

    render() {
        const { loggingIn } = this.props;

        return (
            <LoginForm onSubmit={this.handleSubmit} loggingIn={loggingIn}/>
        );
    }
}

function mapStateToProps(state: RootState) {
    const { loggingIn } = state.authentication;

    return {
        loggingIn
    };
}

const connectedLoginPage = connect(mapStateToProps)(Login);
export { connectedLoginPage as LoginPage };
