import axios from 'axios';

const jwt = () => {
    axios.interceptors.request.use((config) => {
        const raw = localStorage.getItem('user') ?? '';

        if ('' === raw) {
            return config;
        }

        const user = JSON.parse(raw);

        if (user && user.token) {
            config.headers['authorization'] = `Bearer ${user.token}`;
        }

        return config;
    });
}

export default jwt;
