import React from 'react';
import { PrivateRoute } from '../routing';
import { createComponent, editGallery, listComponent, showComponent } from './components';

const routes = [
    <PrivateRoute path="/galleries/" component={listComponent} exact strict key="list"/>,
    <PrivateRoute path="/galleries/create" component={createComponent} exact strict key="create"/>,
    <PrivateRoute path="/galleries/:id" component={showComponent} exact key="show"/>,
    <PrivateRoute path="/galleries/:id/edit" component={editGallery} exact key="edit"/>,
];

export default routes;
