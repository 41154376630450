import { userConstants } from '../../constants/authentication/user.constants';

const rawUser = localStorage.getItem('user');
let user = null;

if (rawUser) {
    user = JSON.parse(rawUser);
}

const initialState = user ? { loggedIn: true, user: user, loggingIn: false } : {};

export default function authentication(state: any = initialState, action: any) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}
