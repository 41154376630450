import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../assets/images/logo.jpg';
import Link from '@material-ui/core/Link';
import { BasePassword, BaseText } from '../../../core';

const useStyles = makeStyles((theme) => ({
    root: {},
    form: {
        display: 'flex',
        justifyContent: 'center'
    },
}));

export const LoginForm = (props: any) => {
    const classes = useStyles();
    const fullWidth = true;

    return (
        <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={Yup.object({
                username: Yup.string()
                    .email()
                    .required('Required'),
                password: Yup.string()
                    .max(255, 'Must be 255 characters or less')
                    .required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                props.onSubmit(values);
                setSubmitting(false);
            }}
        >
            <Form className={classes.form}>
                <Grid item container direction="column" alignContent="center" justify="center" spacing={1} md={4}
                      xs={12}>
                    <Grid item><img src={logo} alt="logo"/></Grid>
                    <Grid item>
                        <BaseText
                            fullWidth={fullWidth}
                            name="username"
                            label="Username"
                        />
                    </Grid>
                    <Grid item>
                        <BasePassword
                            fullWidth={fullWidth}
                            name="password"
                            label="Password"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container alignContent="center" spacing={1}>
                            <Grid item><Button type="submit" variant="contained" color="primary"
                                               disabled={props.loggingIn}>Login</Button></Grid>
                        </Grid>
                    </Grid>
                    <Grid item><Link href="/reset-password">Don't remember password</Link></Grid>
                </Grid>
            </Form>
        </Formik>
    );
};
