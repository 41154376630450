import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';
import { snackbarActions } from '../slices';
import { RootState, useAppSelector } from '../../../store';

export default function AppSnackbar() {
    const dispatch = useDispatch();

    const { successSnackbarMessage, successSnackbarOpen, severity } = useAppSelector(
        (state: RootState) => state.snackbar
    );

    // noinspection JSVoidFunctionReturnValueUsed
    const handleClose = () => dispatch(snackbarActions.clear());

    return <MaterialSnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={successSnackbarOpen}
            autoHideDuration={4000}
            onClose={handleClose}
        >
            <Alert variant="filled" onClose={handleClose} severity={severity}>
                {successSnackbarMessage}
            </Alert>
        </MaterialSnackbar>
}
