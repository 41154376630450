import { Exclude, Expose, Type } from 'class-transformer';
import ThumbnailModel from './thumbnail.model';

@Exclude()
export default class MediaModel {
    @Expose() id!: string;
    @Expose() name!: string;
    @Expose() original!: string;
    @Expose()
    @Type(() => ThumbnailModel)
    thumbnails!: ThumbnailModel;
    @Expose() type!: string;
}
