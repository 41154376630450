import React, { useEffect } from 'react';
import Layout from '../../layout/components/layout.component';
import { match, Redirect, RouteComponentProps } from 'react-router-dom';
import { galleryEditActions, galleryShowActions } from '../slices';
import { Form, GalleryValues } from './form.component';
import { snackbarActions } from '../../snackbar';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store';

interface EditRouteParams extends RouteComponentProps {
    match: { params: { id: string } } & match
}

const EditGallery = (props: EditRouteParams) => {
    const dispatch: AppDispatch = useAppDispatch();
    const { retrieved, loading, success } = useAppSelector(state => state.gallery.edit);
    const id: string = props.match.params.id;

    useEffect(() => {
        dispatch(galleryShowActions.retrieve(props.match.params.id))

        return () => {
            // noinspection JSVoidFunctionReturnValueUsed
            dispatch(galleryEditActions.reset());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (success) {
        return (
            <Redirect to={'/galleries/'}/>
        )
    }

    const save = (values: GalleryValues) => {
        dispatch(galleryEditActions.patch({ id, values })).then(
            () => {
                // noinspection JSVoidFunctionReturnValueUsed
                dispatch(snackbarActions.success('Gallery edited.'));
            }
        );
    }

    return (
        <Layout title={`Gallery edit: ${retrieved && retrieved.name}`}>
            <Form save={save} loading={loading} gallery={retrieved} cancelUrl={`/galleries/${id}`}/>
        </Layout>
    );
};

export default EditGallery;
