import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import MediaModel from '../models/media.model';

type SliceState = {
    error: unknown | null,
    loading: boolean,
    deleted: boolean,
}

const remove = createAsyncThunk(
    'media/delete',
    async (media: MediaModel) => {
        await httpClient.remove(`media-objects/${media.id}`)
    });

const initialState: SliceState = {
    error: null,
    loading: false,
    deleted: false,
};

const slice = createSlice({
    name: 'media/delete',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }),
        success: ((state, action: PayloadAction<any>) => {
            state.deleted = true;
        }),
        reset: ((state) => {
            const { error, loading, deleted } = { ...initialState };

            state.error = error;
            state.loading = loading;
            state.deleted = deleted;
        }),
    }
});

export const mediaDeleteActions = { ...slice.actions, remove };
export default slice.reducer;
