import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { backdropLoaderActions } from '../../features/backdrop-loader';
import { Store } from 'redux';
import { AppRequestOptions } from '../client.service';

type AppConfigRequest = {
    config: AxiosRequestConfig & AppRequestOptions
}

const backdropLoader = (store: Store) => {
    axios.interceptors.request.use((config: AxiosRequestConfig & AppRequestOptions) => {
        if (!config.appRequestOptions?.disableBackdropLoader) {
            // noinspection JSVoidFunctionReturnValueUsed
            store.dispatch(backdropLoaderActions.open())
        }

        return config;
    });

    axios.interceptors.response.use((config: AxiosResponse & AppConfigRequest) => {
        if (!config.config.appRequestOptions?.disableBackdropLoader) {
            // noinspection JSVoidFunctionReturnValueUsed
            store.dispatch(backdropLoaderActions.close())
        }

        return config;
    }, err => {
        // noinspection JSVoidFunctionReturnValueUsed
        store.dispatch(backdropLoaderActions.close())

        return Promise.reject(err);
    })
}

export default backdropLoader;
