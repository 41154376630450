import { httpClient } from '../../../http';
import { normalize } from 'normalizr';
import { gallerySchema } from '../schema';
import { QueryPaginationParams } from '../../../core';

export const getList = async (params?: QueryPaginationParams) => {
    const response = await httpClient.get('galleries', { params: params });

    return normalize(response.data, [gallerySchema]).entities;
}

