import { useDropzone } from 'react-dropzone';
import { LinearProgress } from '@material-ui/core';
import './dropzone.scss';
import React from 'react';
import { mediaCreateActions } from '../../media';
import { useAppDispatch, useAppSelector } from '../../../store';

const md5 = require('md5');

export const Dropzone: React.FunctionComponent<any> = (props: any) => {
    const uploadProgress = useAppSelector((state) => state.media.create.progressMap);
    const dispatch = useAppDispatch();

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        onDrop: files => {
            files.forEach((file: File) => {
                const data = new FormData();
                const hash = getFileNameHash(file.name);
                const progressMap: Map<string, { filename: string, progress: number, size: number }> = new Map(uploadProgress);

                data.append('file', file);

                if (progressMap.has(hash)) {
                    return;
                }

                dispatch(mediaCreateActions.save({
                    gallery: props.gallery,
                    data,
                    hash,
                    file
                }));
            })
        }
    });

    const getFileNameHash = (fileName: string) => md5(fileName);

    const files = uploadProgress.map((file: [string, { filename: string, progress: number, size: number }]) => {
        const { filename, progress, size } = { ...file[1] };
        const hash = file[0];

        return (
            <li key={hash}>
                {filename} - {size} bytes
                <LinearProgress variant="determinate" value={progress}/>
            </li>
        )
    });

    return <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
        </aside>
    </section>
}
