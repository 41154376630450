import { httpClient } from '../http';

export const userService = {
    login,
    logout,
    getUser
};

function login(username: string, password: string) {
    return httpClient.post('authentication_token', {
        data: { email: username, password: password }
    }).then(
        result => result.data,
        error => {
            if (error.response.status === 401) {
                logout();
            }

            const err = (error.response && error.response.message) || error.response.statusText;

            return Promise.reject(err);
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getUser() {
    const rawUser = localStorage.getItem('user');
    let user = null;

    if (rawUser) {
        user = JSON.parse(rawUser);
    }

    if (!(user && user.token)) {
        return undefined;
    }

    return user;
}
