import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import MediaModel from '../models/media.model';

type SliceState = {
    error: unknown | null,
    loading: boolean,
    deleted: boolean,
}

const download = createAsyncThunk(
    'media/download',
    async (media: MediaModel) => {
        return await httpClient.get(`media-objects/${media.id}/download`, {
            responseType: 'blob'
        })
            .then(result => {
                const filename: RegExpExecArray | null = /filename[^;=\\n]*=((['"]).*?\\2|[^;\\n]*)/.exec(result.headers['content-disposition']);
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename ? filename[1] : '');
                document.body.appendChild(link);
                link.click();
            })
    });

const initialState: SliceState = {
    error: null,
    loading: false,
    deleted: false,
};

const slice = createSlice({
    name: 'media/download',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }),
        success: ((state, action: PayloadAction<any>) => {

        }),
    }
});

export const mediaDownloadActions = { ...slice.actions, download };
export default slice.reducer;
