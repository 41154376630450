import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, GalleryValues } from './form.component';
import { galleryCreateActions } from '../slices';
import Layout from '../../layout/components/layout.component';
import { snackbarActions } from '../../snackbar';

// todo props and state as typescript
class CreateComponent extends Component<any, any> {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        created: PropTypes.object,
        save: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        snackbarSuccess: PropTypes.func.isRequired
    };

    componentWillUnmount() {
        this.props.reset();
    }

    save(values: GalleryValues) {
        this.props.save(values).then(() => this.props.snackbarSuccess('Gallery created'));
    }

    render() {
        if (this.props.created) {
            return (
                <Redirect
                    to={'/galleries/'}
                />
            );
        }

        return (
            <Layout title="New Gallery">
                {this.props.loading && (
                    <div className="alert alert-info" role="status">
                        Loading...
                    </div>
                )}
                <Form save={(values: GalleryValues) => { this.save(values) }} loading={this.props.loading} cancelUrl={'/galleries/'}/>
            </Layout>
        );
    }
}

const mapState = (state: any) => {
    const { created, loading } = state.gallery.create;

    return { created, loading };
};

const mapDispatch = {
    save: (values: GalleryValues) => galleryCreateActions.save(values),
    reset: () => galleryCreateActions.reset(),
    snackbarSuccess: (message: string) => snackbarActions.success(message)
};

export default connect(mapState, mapDispatch)(CreateComponent);
