import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, match, Redirect, RouteComponentProps } from 'react-router-dom';
import { IconButton, Theme, useMediaQuery, useTheme, WithStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { RootState } from '../../../store';
import { galleryShowActions } from '../slices';
import { mediaDeleteActions, MediaItem, mediaListActions } from '../../media';
import { PayloadAction } from '@reduxjs/toolkit';
import { snackbarActions } from '../../snackbar';
import { SnackbarOpen, SnackbarSeverity } from '../../snackbar/slices/snackbar.slice';
import { ConfirmationDialog } from '../../dialog';
import GalleryModel from '../models/gallery.model';
import MediaModel from '../../media/models/media.model';
import { Dropzone } from '../../dropzone';
import Layout from '../../layout/components/layout.component';
import { Delete, Edit, NavigateBefore } from '@material-ui/icons';
import { Gallery } from '../schema';

export const withMediaQuery = () => (Component: any) => (props: any) => {
    const theme = useTheme();
    const mediaProps = {
        mediaQuery: {
            xs: useMediaQuery(theme.breakpoints.up('xs')),
            sm: useMediaQuery(theme.breakpoints.up('sm')),
            md: useMediaQuery(theme.breakpoints.up('md')),
            lg: useMediaQuery(theme.breakpoints.up('lg')),
            xl: useMediaQuery(theme.breakpoints.up('xl')),
        }
    };

    return <Component {...mediaProps} {...props} />
}

const styles: any = (theme: Theme) => ({
    photo: {
        position: 'relative',
    },
    'photo__speed-dial': {
        bottom: 15,
        position: 'absolute',
        right: 15
    }
});

interface ShowRouteParams extends RouteComponentProps {
    match: { params: { id: string } } & match
}

type Props = {
    deleteGallery: (gallery: GalleryModel | null) => Promise<PayloadAction<undefined>>,
    deleteMedia: (media: MediaModel) => Promise<any>,
    deleted: boolean,
    error: any,
    media: MediaModel[] | null,
    mediaQuery: { xs: boolean, sm: boolean, md: boolean, lg: boolean, xl: boolean },
    reset: () => {},
    // todo find better type for promise
    retrieve: (id: string) => Promise<PayloadAction<GalleryModel, string, { arg: string, requestId: string, requestStatus: "fulfilled" }, never>>,
    gallery: GalleryModel | null,
    // todo find better type for promise
    retrieveMedia: (galleryId: string) => Promise<PayloadAction<MediaModel[], string, { arg: string, requestId: string, requestStatus: "fulfilled" }, never>>,
    snackbarOpen: SnackbarOpen
};

type State = {
    toggleGallery: boolean,
    confirmationDialogOpen: boolean
}

class ShowComponent extends Component<Props & WithStyles & ShowRouteParams, State> {
    private readonly galleryRef: any;

    constructor(props: any) {
        super(props);

        this.state = {
            toggleGallery: false,
            confirmationDialogOpen: false
        };

        this.galleryRef = React.createRef();
    }

    componentDidMount() {
        const getData = async () => {
            const action = await this.props.retrieve(this.props.match.params.id);

            if (action.payload?.id) {
                await this.props.retrieveMedia(action.payload.id);
            }
        };

        getData().then(() => {
        });
    }

    componentWillUnmount() {
        this.props.reset();
    }

    onDeleteClick = () => {
        this.setState({ confirmationDialogOpen: true });
    };

    openGallery = (index: number) => {
        this.setState({ toggleGallery: true }, () => {
            this.galleryRef.current.slideToIndex(index);
            this.galleryRef.current.fullScreen();
        });
    }

    onScreenChange = (toggle: boolean) => {
        if (!toggle) {
            this.setState({ toggleGallery: false });
        }
    }

    onConfirmationDialogConfirm = () => {
        this.onConfirmationDialogClose();
        this.props
            .deleteGallery(this.props.gallery)
            .then((result) => {
                this.props.snackbarOpen('Gallery deleted.');
            });
    }

    onConfirmationDialogClose = () => {
        this.setState({ confirmationDialogOpen: false });
    }

    navigation(item: Gallery) {
        return (
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <IconButton
                        component={Link}
                        to={'/galleries/'}
                        title="Back to galleries list"
                    >
                        <NavigateBefore/>
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        component={Link}
                        to={`${item.id}/edit`}
                        title="Edit gallery"
                    >
                        <Edit/>
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        color="secondary"
                        onClick={this.onDeleteClick}
                        title="Delete gallery"
                    >
                        <Delete/>
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    render() {
        if (this.props.deleted) {
            return <Redirect to={`/galleries/`}/>;
        }

        const classes = this.props.classes;
        const item: GalleryModel | null = this.props.gallery;
        const images: Array<{ original: string, thumbnail: string }> = [];
        const media: MediaModel[] | null = this.props.media;

        media?.forEach((media: MediaModel) => {
            images.push({
                original: media.thumbnails.large.url,
                thumbnail: media.thumbnails.medium.url,
            })
        });

        return (
            <Layout title={`${item && item.name}`}>
                <Grid container spacing={1} direction="column">
                    <Grid item style={{ display: this.state.toggleGallery ? 'block' : 'none' }}>
                        <ImageGallery
                            items={images}
                            ref={this.galleryRef}
                            showPlayButton={false}
                            onScreenChange={this.onScreenChange}
                        />
                    </Grid>
                    <Grid item>
                        <Dropzone gallery={item}/>
                    </Grid>
                    {item && this.navigation(item)}
                    {media && (
                        <Grid container spacing={1}>
                            {media.map((media: MediaModel, index: number) => (
                                <Grid item key={media.id} xs={6} md={4} lg={3} className={classes.photo}>
                                    <MediaItem media={media}
                                               deleteMedia={() => this.props.deleteMedia(media).then(
                                                   () => this.props.snackbarOpen('Media deleted.')
                                               )}
                                               openGallery={() => this.openGallery(index)}
                                               speedDialClass={classes['photo__speed-dial']}/>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {item && item.mediaCount > 1 && this.navigation(item)}
                </Grid>
                <ConfirmationDialog
                    title="Delete confirmation"
                    content="Gallery will be deleted permanently. Still want to continue?"
                    onConfirm={this.onConfirmationDialogConfirm}
                    openDialog={this.state.confirmationDialogOpen}
                    onClose={this.onConfirmationDialogClose}/>
            </Layout>
        );
    }
}

const mapState = (state: RootState) => ({
    gallery: state.gallery.show.gallery,
    error: state.gallery.show.error,
    deleted: state.gallery.show.deleted,
    media: state.gallery.show.media
});

const mapDispatch = {
    retrieve: (id: string) => galleryShowActions.retrieve(id),
    retrieveMedia: (galleryId: string) => mediaListActions.retrieve(galleryId),
    deleteGallery: (item: GalleryModel) => galleryShowActions.deleteGallery(item),
    reset: galleryShowActions.reset,
    deleteMedia: (item: MediaModel) => mediaDeleteActions.remove(item),
    snackbarOpen: (message: string, severity?: SnackbarSeverity) => snackbarActions.open({ message, severity })
};

export default connect(mapState, mapDispatch)(withStyles(styles)(withMediaQuery()(ShowComponent)));
