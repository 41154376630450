import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import GalleryModel from '../../gallery/models/gallery.model';
import { normalize } from 'normalizr';
import { Media, mediaItemSchema } from '../schema';

type SavePayload = {
    gallery: GalleryModel,
    data: FormData,
    hash: string,
    file: File,
};

type SliceState = {
    error: unknown | null,
    loading: boolean,
    created: Media | null,
    progressMap: any[]
}

type ThumbnailJson = { url: string };

type MediaPayloadJson = {
    id: string,
    name: string,
    original: string,
    thumbnails: {
        small: ThumbnailJson,
        medium: ThumbnailJson,
        large: ThumbnailJson,
    },
    type: string,
}

type ProgressPayload = {
    progress: number,
    hash: string,
    fileName: string,
    fileSize: number
};

const save = createAsyncThunk(
    'media/save',
    async (values: SavePayload, thunkAPI) => {
        const response = await httpClient.post(`galleries/${values.gallery.id}/media-objects`,
            {
                appRequestOptions: {
                    disableBackdropLoader: true
                },
                data: values.data,
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    thunkAPI.dispatch(mediaCreateActions.progress({
                        progress,
                        hash: values.hash,
                        fileName: values.file.name,
                        fileSize: values.file.size,
                    }))
                }
            });

        const normalized = normalize(response.data as MediaPayloadJson, mediaItemSchema);

        if (normalized.entities.mediaItems) {
            return normalized.entities.mediaItems[normalized.result];
        }

        return undefined;
    });

const initialState: SliceState = {
    error: null,
    loading: false,
    created: null,
    progressMap: [],
};

const slice = createSlice({
    name: 'media/create',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }),
        success: ((state, action: PayloadAction<Media>) => {
            state.created = action.payload;
        }),
        reset: ((state) => {
            state.created = null;
            state.loading = false;
            state.error = null;
            state.progressMap = [];
        }),
        progress: ((state, action: PayloadAction<ProgressPayload>) => {
            const progressMap = new Map(state.progressMap);
            const { hash, fileName, fileSize, progress } = { ...action.payload };

            if (progress >= 100) {
                progressMap.delete(hash);
            } else {
                progressMap.set(hash, { progress, filename: fileName, size: fileSize });
            }

            state.progressMap = Array.from(progressMap.entries());
        })
    }
});

export const mediaCreateActions = { ...slice.actions, save };
export default slice.reducer;
