import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Gallery } from '../schema';
import { NormalizedData } from '../../../core';
import { galleryApi } from '../services';

const retrieve = createAsyncThunk('gallery/list/retrieve', galleryApi.getList);

type SliceState = {
    error: unknown | null,
    loading: boolean,
    galleries: NormalizedData<Gallery> | undefined
}

const initialState: SliceState = {
    error: null,
    loading: false,
    galleries: undefined
};

const slice = createSlice({
    name: 'gallery/list',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        success: ((state, action: PayloadAction<any>) => {
            state.galleries = action.payload;
        }),
        reset: ((state) => {
            const { galleries, loading, error } = initialState;

            state.galleries = galleries;
            state.loading = loading;
            state.error = error;
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                retrieve.pending,
                (state) => {
                    state.loading = true;
                })
            .addCase(
                retrieve.fulfilled,
                (state, action) => {
                    state.galleries = action.payload.galleries;
                    state.loading = false;
                })
            .addCase(
                retrieve.rejected,
                (state, action) => {
                    // state.error = action.error.message;
                    state.loading = false;
                })
    }
});

export const galleryListActions = { ...slice.actions, retrieve };
export default slice.reducer;
