import axios, { AxiosRequestConfig, Method } from 'axios';

export type AppRequestOptions = {
    appRequestOptions?: {
        disableBackdropLoader?: boolean
    }
}

const get = (url: string, options: AxiosRequestConfig & AppRequestOptions = {}) => call('get', url, options);

const post = (url: string, options: AxiosRequestConfig & AppRequestOptions = {}) => call('post', url, options);

const patch = (url: string, options: AxiosRequestConfig & AppRequestOptions = {}) => call('patch', url, options);

const put = (url: string, options: AxiosRequestConfig & AppRequestOptions = {}) => call('put', url, options);

const remove = (url: string, options: AxiosRequestConfig & AppRequestOptions = {}) => call('delete', url, options);

function call(method: Method, url: string, options: AxiosRequestConfig & AppRequestOptions = {}) {
    const defaultMimeType = 'application/json';
    const ENTRYPOINT = process.env.REACT_APP_API_URL;

    if (undefined === options.headers) {
        options.headers = new Headers();
    }

    if (!options.headers.has('Accept')) {
        options.headers.set('Accept', defaultMimeType);
    }

    if (!options.headers.has('Content-Type')) {
        options.headers.set('Content-Type', defaultMimeType);
    }

    // if (
    //     undefined !== options.data &&
    //     !(options.data instanceof FormData) &&
    //     !options.headers.has('Content-type')
    // ) {
    //     options.headers.set('Content-type', defaultMimeType);
    // }

    const config = options;

    config.method = method;
    config.url = ENTRYPOINT + url;
    config.headers = Object.fromEntries(options.headers.entries());

    return axios(config)
        .then(result => result)
        .catch(e => {
            throw e;
        });
}

export { get, post, patch, put, remove };
