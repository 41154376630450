import React, { useState } from 'react';
import { Hidden, WithStyles } from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Delete, GetApp, Image } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { ConfirmationDialog } from '../../dialog';
import MediaModel from '../models/media.model';
import { mediaDownloadActions } from '../slices';

type MediaItemProps = {
    media: MediaModel,
    speedDialClass: string,
    deleteMedia: () => Promise<any>,
    openGallery: () => void,
    classes: { img: any }
} & WithStyles;

export const MediaItem = withStyles((theme) => (
    {
        img: {
            '&:hover': {
                cursor: 'pointer',
                '-webkit-box-shadow': '3px 3px 15px 5px #E0E0E0',
                'box-shadow': '3px 3px 15px 5px #E0E0E0',
                position: 'relative',
                left: '-1px',
                top: '-1px',
            }
        }
    })
)((props: MediaItemProps) => {
    const { media, speedDialClass, deleteMedia, openGallery, classes } = props;
    const [open, setOpen] = useState(false);
    const [dialog, setDialog] = useState(null);
    const dispatch = useDispatch();

    const actions = [
        {
            icon: <Delete/>,
            name: 'Delete',
            handleClick: (item: any) => {
                const dialog = renderDialog();

                // todo fix setDialog(dialog);
                setDialog(dialog as any);
            }
        },
        {
            icon: <Image/>,
            name: 'Open gallery',
            handleClick: () => {
                openGallery();
            }
        },
        {
            icon: <GetApp/>,
            name: 'Download',
            handleClick: (item: any) => {
                dispatch(mediaDownloadActions.download(item))
            }
        },
    ];

    const renderDialog = () => {
        return (
            <ConfirmationDialog
                title="Delete confirmation"
                content="Selected media will be deleted permanently. Still want to continue?"
                onConfirm={deleteMedia}
                openDialog={true}
                onClose={dialogClose}/>
        );
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const dialogClose = () => {
        setDialog(null);
    }

    return (
        <>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <img src={media.thumbnails.small.url} alt={media.name} onClick={openGallery} className={classes.img}/>
            </Hidden>
            <Hidden only={['xs', 'lg', 'xl']}>
                <img src={media.thumbnails.medium.url} alt={media.name} onClick={openGallery} className={classes.img}/>
            </Hidden>
            <Hidden only={['xs', 'sm', 'md']}>
                <img src={media.thumbnails.large.url} alt={media.name} onClick={openGallery} className={classes.img}/>
            </Hidden>
            <SpeedDial
                ariaLabel="Photo menu"
                icon={<SpeedDialIcon/>}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                className={speedDialClass}
                direction="up"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => action.handleClick(media)}
                    />
                ))}
            </SpeedDial>
            {dialog}
        </>
    );
});
