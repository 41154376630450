import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SnackbarSeverity = 'success' | 'error' | 'warning' | 'info';
export type SnackbarOpen = (message: string, severity?: SnackbarSeverity) => void;

type SliceState = {
    successSnackbarOpen: boolean,
    successSnackbarMessage: string | null,
    severity: 'success' | 'error' | 'warning' | 'info',
}

const initialState: SliceState = {
    successSnackbarOpen: false,
    successSnackbarMessage: null,
    severity: 'success'
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<any>) => {
            state.successSnackbarOpen = true;
            state.successSnackbarMessage = action.payload.message;
            state.severity = action.payload.severity ?? 'success';
        },
        success: (state, action: PayloadAction<any>) => {
            state.successSnackbarOpen = true;
            state.successSnackbarMessage = action.payload;
            state.severity = 'success';
        },
        clear: state => {
            const { successSnackbarOpen, successSnackbarMessage, severity } = initialState;

            state.successSnackbarOpen = successSnackbarOpen;
            state.successSnackbarMessage = successSnackbarMessage;
            state.severity = severity;
        },
    }
});

export const snackbarActions = { ...snackbarSlice.actions };
export default snackbarSlice.reducer;
