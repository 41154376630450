import { useField } from "formik";
import { TextField } from '@material-ui/core';
import React from 'react';

const BaseText = (props: any) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    return (
        <>
            <TextField
                {...props}
                name={field.name}
                error={meta.touched && meta.error !== undefined}
                helperText={meta.error}
                onChange={field.onChange}
                onBlur={field.onBlur}
            />
        </>
    );
};

export default BaseText;
