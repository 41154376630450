import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import { GalleryValues } from '../components/form.component';
import GalleryModel from '../models/gallery.model';
import { galleryShowActions } from './show.slice';
import { Gallery, gallerySchema } from '../schema';
import { normalize } from 'normalizr';

const patch = createAsyncThunk<Gallery, { id: string, values: GalleryValues }>(
    'gallery/patch',
    async (data: { id: string, values: GalleryValues }) => {
        const response = await httpClient.patch(`galleries/${data.id}`, { data: data.values });
        const normalized = normalize(response.data, gallerySchema);

        if (normalized.entities.galleries) {
            return normalized.entities.galleries[normalized.result];
        }

        return undefined;
    }
);

// todo check diff between unknown & null
type SliceType = {
    error: unknown | null,
    loading: boolean,
    retrieved: GalleryModel | undefined,
    success: boolean
}

const initialState: SliceType = {
    error: null,
    loading: false,
    retrieved: undefined,
    success: false
};

const slice = createSlice({
    name: 'gallery/edit',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<unknown>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }),
        reset: ((state) => {
            const { error, loading, retrieved, success } = initialState;

            state.success = success;
            state.retrieved = retrieved;
            state.loading = loading;
            state.error = error;
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                galleryShowActions.retrieve.fulfilled,
                (state, action) => {
                    state.retrieved = action.payload;
                })
            .addCase(
                patch.fulfilled,
                (state) => {
                    state.success = true;
                }
            )
    }
});

export const galleryEditActions = { ...slice.actions, patch };
export default slice.reducer;
