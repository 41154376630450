import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../store';
import { backdropLoaderActions } from '../slices';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function AppBackdropLoader() {
    const open = useAppSelector((state) => state.backdropLoader.open);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const transitions = {
        exit: 300
    };

    const handleClose = () => {
        // noinspection JSVoidFunctionReturnValueUsed
        dispatch(backdropLoaderActions.close())
    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose} transitionDuration={transitions}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    );
}
