import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import { Media, mediaCreateActions, mediaDeleteActions, mediaListActions } from '../../media';
import { normalize } from 'normalizr';
import { Gallery, gallerySchema } from '../schema';
import { NormalizedData } from '../../../core';

const deleteGallery = createAsyncThunk(
    'gallery/delete',
    async (item: Gallery) => {
        await httpClient.remove(`galleries/${item.id}`);
    }
);

const retrieve = createAsyncThunk(
    'gallery/fetch',
    async (id: string) => {
        const response = await httpClient.get(`galleries/${id}`);
        const normalized = normalize(response.data, gallerySchema);

        if (normalized.entities.galleries) {
            return normalized.entities.galleries[normalized.result];
        }

        return undefined;
    });

type SliceState = {
    deleted: boolean,
    error: unknown | null,
    loading: boolean,
    gallery: NormalizedData<Gallery> | undefined,
    media: Media[]
}

const initialState: SliceState = {
    deleted: false,
    error: null,
    loading: false,
    gallery: undefined,
    media: []
};

const slice = createSlice({
    name: 'gallery/show',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }),
        success: ((state, action: PayloadAction<any>) => {
            state.gallery = action.payload;
        }),
        reset: ((state) => {
            const { deleted, gallery, loading, error, media } = initialState;

            state.deleted = deleted;
            state.gallery = gallery;
            state.loading = loading;
            state.error = error;
            state.media = media;
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                deleteGallery.fulfilled,
                (state) => {
                    state.gallery = undefined;
                    state.deleted = true;
                }
            )
            .addCase(
                mediaListActions.retrieve.fulfilled,
                (state, action) => {
                    if (action.payload.entities.mediaItems) {
                        state.media = Object.values<Media>(action.payload.entities.mediaItems);
                    }
                })
            .addCase(
                retrieve.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.gallery = action.payload;
                }
            )
            .addCase(
                retrieve.pending,
                (state) => {
                    state.loading = true;
                }
            )
            .addCase(
                retrieve.rejected,
                (state, action) => {
                    state.loading = false;
                    state.error = action.error;
                }
            )
            .addCase(
                mediaCreateActions.save.fulfilled,
                (state, action) => {
                    // todo reorder by created time
                    state.media.splice(0, 0 , action.payload);
                }
            )
            .addCase(
                mediaDeleteActions.remove.fulfilled,
                (state, action) => {
                    const index = state.media.findIndex((media) => media.id === action.meta.arg.id);

                    if (-1 !== index) {
                        state.media.splice(index, 1);
                    }
                }
            )
    }
});

export const galleryShowActions = { ...slice.actions, retrieve, deleteGallery };
export default slice.reducer;
