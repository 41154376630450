import { userConstants } from '../../constants/authentication/user.constants';
import { alertActions } from './alert.actions';
import { userService } from '../../services/user.service';
import { history } from '../../helpers/history.helper';
import { AppDispatch } from '../../store';

export const userActions = {
    login,
    logout,
};

function login(username: string, password: string) {
    return (dispatch: AppDispatch) => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    // todo upewnic sie, ze to tutaj jest potrzebne
                    localStorage.setItem('user', JSON.stringify(user));

                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user: any) {
        return { type: userConstants.LOGIN_REQUEST, user }
    }

    function success(user: any) {
        return { type: userConstants.LOGIN_SUCCESS, user }
    }

    function failure(error: any) {
        return { type: userConstants.LOGIN_FAILURE, error }
    }
}

function logout() {
    userService.logout();

    history.push('/login');

    return { type: userConstants.LOGOUT };
}

