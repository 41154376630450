import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../../http';
import { normalize } from 'normalizr';
import { Gallery, gallerySchema } from '../schema';

const save = createAsyncThunk(
    'gallery/save',
    async (values: any) => {
        const response = await httpClient.post('galleries', { data: values })
        const normalized = normalize(response.data, gallerySchema);

        if (normalized.entities.galleries) {
            return normalized.entities.galleries[normalized.result];
        }

        return undefined;
    });

type SliceState = {
    error: unknown | null,
    loading: boolean,
    created: Gallery | null
}

const initialState: SliceState = {
    error: null,
    loading: false,
    created: null
};

const slice = createSlice({
    name: 'gallery/create',
    initialState,
    reducers: {
        error: ((state, action: PayloadAction<any>) => {
            state.error = action.payload;
        }),
        loading: ((state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }),
        success: ((state, action: PayloadAction<any>) => {
            state.created = action.payload;
        }),
        reset: ((state) => {
            state.created = null;
            state.loading = false;
            state.error = null;
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                save.fulfilled,
                (state, action) => {
                    state.created = action.payload;
                    state.loading = false;
                })
            .addCase(
                save.pending,
                (state) => {
                    state.loading = true;
                })
            .addCase(
                save.rejected,
                (state, action) => {

                    // dispatch(galleryCreateActions.loading(false));
                    //
                    // if (e instanceof SubmissionError) {
                    //     dispatch(galleryCreateActions.error(e.errors._error));
                    //
                    //     throw e;
                    // }
                    //
                    // dispatch(galleryCreateActions.error(e.message));

                    // state.error = action.error.message;
                    state.loading = false;
                })
     }
});

export const galleryCreateActions = { ...slice.actions, save };
export default slice.reducer;
