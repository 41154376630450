import { createSlice } from '@reduxjs/toolkit';

type SliceState = {
    open: boolean
}

const initialState: SliceState = {
    open: false,
};

const slice = createSlice(
    {
        name: 'backdrop',
        initialState,
        reducers: {
            close: (state) => {
                state.open = false
            },
            open: (state) => {
                if (state.open) {
                    return;
                }

                state.open = true
            },
            reset: (state) => {
                const { open } = initialState;

                state.open = open;
            }
        },
        extraReducers: (builder) => {

        }
    }
);

export const backdropLoaderActions = { ...slice.actions };
export default slice.reducer;
